<template>
    <div class="add-new-ocr">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.ocrCreate')"
            :title="$t('messages.ocrCreate')"
            @submitPressed="submitPressed"
        />
        <ocr-form
            :ocrObject="ocr"
            :action="action"
            @clearAction="action = null"
        />
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import OcrForm from "./components/OcrForm.vue";

export default {
    components: {
        TitleBar,
        OcrForm,
    },
    data() {
        return {
            action: null,
            ocr: {
                timeOfCSRIssue: new Date()
            },
        };
    },
    methods: {
        submitPressed() {
            this.action = "add";
        },
    },
};
</script>
